import React from "react"
import { graphql} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo";

import SinglePost from '../components/single-page-service/index'


const SC = ({data}) => {
  let d = data.strapiSpecialtyCategories;
  return(
    <Layout>
      <SEO title={`tets`}/>
      <SinglePost data={d} cat={`specialty-category`}/>  
    </Layout>
  )
}

export default SC

export const query = graphql`
  query SpecialtyCategoryTemplate($slug: String!) {
    strapiSpecialtyCategories(slug: { eq: $slug }) {
      id
      name
      excerpt
      content
      slug
      feature_image {
        childImageSharp {
          fluid {
            src
          }
        }
      }
    }
  }
` 

